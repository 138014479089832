<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    persistent
    scrollable
  >
    <v-card>
      <v-card-title>
        <span>{{ formTitle }}</span>
        <v-spacer />
        <v-icon @click="closeDialog">
          mdi-close
        </v-icon>
      </v-card-title>

      <ErrorAlert />

      <v-card-text class="pt-5 pb-0">
        <v-form
          ref="form"
          v-model="formValidity"
        >
          <v-text-field
            v-model="form.name"
            :rules="nameRules"
            :counter="30"
            outlined
            dense
            label="Name"
            @keypress.enter="saveItem"
          />
          <v-textarea
            v-model="form.description"
            :rules="descriptionRules"
            :counter="100"
            outlined
            dense
            label="Description"
            auto-grow
          />
          <v-select
            v-model="form.device_type"
            :items="deviceTypeOptions"
            outlined
            clearable
            dense
            label="Device Type"
          />
          <v-text-field
            v-model="form.downlink_field"
            :rules="downlikFieldRules"
            outlined
            dense
            label="Downlink Field"
            @keypress.enter="saveItem"
          />
          <v-text-field
            v-model="form.payload_raw"
            :rules="payloadRawRules"
            outlined
            dense
            label="Payload Raw"
            @keypress.enter="saveItem"
          />
          <v-select
            v-model="form.status"
            :rules="statusRules"
            :items="[1,0]"
            outlined
            dense
            label="Status"
            hint="Expected value from uplink payload after command is executed"
            persistent-hint
          />
        </v-form>
      </v-card-text>

      <v-card-actions class="pa-3">
        <v-spacer />
        <v-btn
          text
          :disabled="loading"
          @click="closeDialog"
        >
          Cancel
        </v-btn>
        <v-btn
          class="primary"
          :loading="loading"
          @click="saveItem"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import FormMixin from '../../mixins/FormMixin';
import { deviceTypeOptions } from '../../utils/shared';

export default {
  name: 'CommandForm',
  mixins: [FormMixin],

  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      form: {
        name: '',
        description: '',
        downlink_field: null,
        payload_raw: null,
        status: null,
        device_type: null,
      },
      defaultForm: {
        name: '',
        description: '',
        downlink_field: null,
        payload_raw: null,
        status: null,
        device_type: null,
      },
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => v.length <= 30 || 'Name must be less than 30 characters',
      ],
      descriptionRules: [
        (v) => !!v || 'Description is required',
        (v) => v == null || (v != null && v.length <= 100) || 'Description must be less than 100 characters',
      ],
      downlikFieldRules: [
        (v) => !!v || 'Downlink Field is required',
      ],
      statusRules: [
        (v) => v !== null || 'Status is required',
      ],
      payloadRawRules: [
        (v) => !!v || 'Payload Raw is required',
      ],
    };
  },

  computed: {
    ...mapGetters(['user', 'organizations']),

    deviceTypeOptions() {
      return deviceTypeOptions.filter((t) => t.value !== 'sensor' && t.value !== 'actuator');
    },
  },

  methods: {
    addData() {
      return this.$api.addCommand(this.formData);
    },

    updateData() {
      return this.$api.updateCommand({
        id: this.form.id,
        ...this.formData,
      });
    },
  },
};
</script>
